import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Img,
  Radio,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { RagieConnectEndpoint } from '@core/types/types.endpoint.ragie';
import { getIntegrations } from '@core/util/util.getIntegrations';

import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppOnboardingUpgrade() {
  const integrations = getIntegrations({
    exclude: ['questionbase'],
  });

  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(
    null,
  );

  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const selectedBg = useColorModeValue('blue.50', 'blue.900');
  const integrationHoverBg = useColorModeValue('gray.50', 'gray.700');
  const integrationSelectedBg = useColorModeValue('blue.50', 'blue.800');

  const submitter = useSubmitter(async (sourceType: string) => {
    const [, res] = await callApi<RagieConnectEndpoint>('/ragie/connect', {
      sourceType,
      local: window.location.host === 'localhost:3000' ? '1' : undefined,
      redirectTo: '/settings?success=onboarding',
    });
    if (res?.url) {
      window.location.href = res.url;
    }
  });

  const handleConnectClick = () => {
    if (selectedIntegration) {
      submitter.submit(selectedIntegration);
    }
  };

  return (
    <>
      <Text fontSize="xl" fontWeight="bold" mb={6}>
        Your current plan
      </Text>

      <Flex gap={6} direction={{ base: 'column', md: 'row' }} mb={4}>
        <Box
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          borderColor="blue.500"
          bg={selectedBg}
          flex="1"
          position="relative"
          transition="all 0.2s"
        >
          <Radio
            value="free"
            position="absolute"
            top={4}
            right={4}
            isChecked={true}
          />
          <Text fontWeight="bold" fontSize="lg" mb={2}>
            Free Plan
          </Text>
          <Text mb={4}>Try it out with limited features</Text>
          <Text fontWeight="bold" fontSize="2xl" mb={4}>
            $0
          </Text>
          <Stack spacing={2}>
            <Text>✓ 1 integration</Text>
            <Tooltip
              label={
                <Text>
                  We use Ragie.ai for document processing. For PDFs and
                  PowerPoint files, the page count is determined by the number
                  of pages in the document.
                  <br />
                  <br />
                  For other document types, a page is defined as 3,000
                  characters, reflecting the average character count for a
                  single page. Ragie supports fractional pages, meaning if your
                  document contains 1,500 characters, it will count as 0.5 of a
                  page.
                </Text>
              }
              hasArrow
              placement="top"
            >
              <Flex alignItems="center">
                <Text>✓ Up to 20 pages synced</Text>
                <Icon as={FaInfoCircle} ml={1} fontSize="xs" />
              </Flex>
            </Tooltip>
            <Text>✓ Up to 100 saved answers</Text>
          </Stack>
        </Box>

        <Box
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          borderColor={borderColor}
          bg="transparent"
          flex="1"
          position="relative"
          transition="all 0.2s"
          opacity={0.7}
        >
          <Text
            position="absolute"
            top={4}
            right={4}
            fontSize="sm"
            color="blue.500"
            fontWeight="medium"
          >
            Upgrade later
          </Text>
          <Text fontWeight="bold" fontSize="lg" mb={2}>
            Pro Plan
          </Text>
          <Text mb={4}>Full access to all features</Text>
          <Text fontWeight="bold" fontSize="2xl" mb={4}>
            $5 / user / month
          </Text>
          <Stack spacing={2}>
            <Text>✓ Unlimited integrations</Text>
            <Text>✓ Up to 100 pages synced per user</Text>
            <Text>✓ $0.03 per additional page</Text>
          </Stack>
        </Box>
      </Flex>

      <Box mt={12}>
        <Heading size="md" mb={3}>
          Select your integration
        </Heading>
        <Text mb={4} fontSize="sm" color="gray.600">
          Answer questions in Slack with data from:
        </Text>
        <Flex flexWrap="wrap" gap={3}>
          {integrations.map(({ id, icon, name, description }) => (
            <Box
              key={id}
              as="button"
              p={4}
              width="200px"
              height="150px"
              borderWidth="1px"
              borderRadius="lg"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              onClick={() => setSelectedIntegration(id)}
              _hover={{ bg: integrationHoverBg }}
              bg={
                selectedIntegration === id
                  ? integrationSelectedBg
                  : 'transparent'
              }
              borderColor={
                selectedIntegration === id ? 'blue.500' : borderColor
              }
              position="relative"
              transition="all 0.2s"
            >
              {selectedIntegration === id && (
                <Icon
                  as={HiCheck}
                  position="absolute"
                  top={2}
                  right={2}
                  color="blue.500"
                  boxSize={5}
                />
              )}
              <Img src={icon} w={8} h={8} mb={3} />
              <Text fontWeight="medium" mb={2}>
                {name}
              </Text>
              <Text fontSize="xs" color="gray.600">
                {description}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
      <Text mt={4} fontSize="sm" color="gray.600" fontStyle="italic">
        * Be selective, you only sync 20 pages in the free plan.
      </Text>
      <Flex mt={4} gap={3}>
        <Button
          colorScheme="action"
          size="sm"
          onClick={handleConnectClick}
          isLoading={submitter.isSubmitting}
          isDisabled={!selectedIntegration}
        >
          Connect{' '}
          {selectedIntegration
            ? `${integrations.find((i) => i.id === selectedIntegration)?.name}`
            : ''}
        </Button>
        <Button
          colorScheme="action"
          size="sm"
          variant="outline"
          onClick={() => {
            window.location.href = '/settings?success=onboarding';
          }}
        >
          I don&apos;t need an integration
        </Button>
      </Flex>
    </>
  );
}
