import { BsFillLightningChargeFill } from 'react-icons/bs';

import {
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { RagieConnectEndpoint } from '@core/types/types.endpoint.ragie';
import { getIntegrations } from '@core/util/util.getIntegrations';
import { isPro } from '@core/util/util.isPro';

import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsAddIntegrationButton({
  connectionCount,
}: {
  connectionCount: number;
}) {
  const team = useDirectoryTeam();
  const integrations = getIntegrations({
    exclude: ['questionbase'],
  });

  const submitter = useSubmitter(async (sourceType: string) => {
    const [, res] = await callApi<RagieConnectEndpoint>('/ragie/connect', {
      sourceType,
      local: window.location.host === 'localhost:3000' ? '1' : undefined,
      redirectTo: `/settings?success=integration`,
    });
    if (res?.url) {
      window.location.href = res.url;
    }
  });

  const isMaxedOut = !isPro(team) && connectionCount >= 1;
  const button = (
    <MenuButton
      as={Button}
      colorScheme="action"
      leftIcon={<BsFillLightningChargeFill />}
      size="sm"
      mt={2}
      isLoading={submitter.isSubmitting}
      isDisabled={isMaxedOut}
    >
      Add integration
    </MenuButton>
  );

  return (
    <Menu>
      {isMaxedOut ? (
        <Tooltip
          label="Only 1 integration is supported on the free plan"
          placement="end"
        >
          {button}
        </Tooltip>
      ) : (
        button
      )}
      <MenuList>
        {integrations.map((integration) => (
          <MenuItem
            key={integration.id}
            onClick={() => submitter.submit(integration.id)}
            fontSize="sm"
            fontWeight={500}
          >
            <Image src={integration.icon} w={6} h={6} mr={3} />
            {integration.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
