export function getIntegrations({
  baseUrl = '',
  exclude = [],
  include,
}: {
  baseUrl?: string;
  exclude?: string[];
  include?: string[];
}) {
  return [
    {
      name: 'Question Base',
      id: 'questionbase',
      icon: `${baseUrl}/favicon.ico`,
      description: 'Search in questions and answers',
    },
    {
      name: 'Confluence',
      id: 'confluence',
      icon: `${baseUrl}/assets/services/confluence.ico`,
      description: 'Search in Spaces, pages, blog posts and databases',
    },
    {
      name: 'Dropbox',
      id: 'dropbox',
      icon: `${baseUrl}/assets/services/dropbox.ico`,
      description: 'Search in folders and files',
    },
    {
      name: 'Freshdesk',
      id: 'freshdesk',
      icon: `${baseUrl}/assets/services/freshdesk.ico`,
      description: 'Search in tickets and articles',
    },
    {
      name: 'Google Drive',
      id: 'google_drive',
      icon: `${baseUrl}/assets/services/gdrive.png`,
      description: 'Search in folders, docs, spreadsheets and slides',
    },
    {
      name: 'Hubspot',
      id: 'hubspot',
      icon: `${baseUrl}/assets/services/hubspot.png`,
      description: 'Search in contacts, deals, notes and tickets',
    },
    {
      name: 'Notion',
      id: 'notion',
      icon: `${baseUrl}/assets/services/notion.ico`,
      description: 'Search in pages, images and databases',
    },
  ]
    .filter((i) => !exclude.includes(i.id))
    .filter((i) => (include ? include.includes(i.id) : true));
}

export function getIntegration(id: string) {
  const integration = getIntegrations({}).find((i) => i.id === id);

  if (!integration) {
    throw new Error(`Integration ${id} not found`);
  }

  return integration;
}
