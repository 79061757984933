import { useEffect, useRef, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { Box, Button, Collapse, Textarea } from '@chakra-ui/react';
import { Checkbox, OrderedList, Text } from '@chakra-ui/react';
import { OnboardingSurveyEndpoint } from '@core/types/types.endpoint.onboarding';

import { ListEl } from 'src/App/App.ListEl';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

const tools = [
  'Slack',
  'Notion',
  'Google Docs',
  'Confluence',
  'Intercom',
  'Zendesk',
  'No documentation yet',
  'Other…',
];

export function AppOnboardingSurvey() {
  const updater = useDirectoryUpdater();
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const [problemInput, setProblemInput] = useState('');
  const [referralInput, setReferralInput] = useState('');
  const isOtherToolSelected = selectedTools.includes('Other…');
  const [otherToolInput, setOtherToolInput] = useState('');
  // Select the input on slide in
  const otherToolInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (otherToolInputRef.current && isOtherToolSelected) {
      setTimeout(() => {
        otherToolInputRef.current?.focus();
      }, 100);
    }
  }, [isOtherToolSelected]);

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<OnboardingSurveyEndpoint>(
      '/onboarding/survey',
      {
        // Replace 'Other…' with the actual input
        tools: selectedTools.map((t) => (t === 'Other…' ? otherToolInput : t)),
        problem: problemInput,
        referral: referralInput,
      },
    );

    updater(res);
  });

  return (
    <>
      <OrderedList>
        <ListEl title="Which tools do you want to connect?">
          <Text mt={4} mb={6}>
            Select every option that applies.
          </Text>
          {tools.map((tool) => (
            <Box key={tool} mt={2}>
              <Checkbox
                isChecked={selectedTools.includes(tool)}
                onChange={(e) => {
                  // If the option is already selected, remove it from the array
                  // Otherwise, add it to the array
                  setSelectedTools(
                    e.target.checked
                      ? [...selectedTools, tool]
                      : selectedTools.filter((t) => t !== tool),
                  );
                }}
              >
                {tool}
              </Checkbox>
              {/* Option is other & is selectedTools, show a text input, animate it in */}
              {tool === 'Other…' && (
                <Collapse in={isOtherToolSelected} animateOpacity>
                  <Box py={4} px={0.5}>
                    <Textarea
                      as={ReactTextareaAutosize}
                      minRows={1}
                      lineHeight="16px"
                      fontSize="13px"
                      pt={3.5}
                      pb={4}
                      minH="auto"
                      ref={otherToolInputRef}
                      placeholder="Type your tool(s)…"
                      value={otherToolInput}
                      onChange={(e) => setOtherToolInput(e.target.value)}
                      resize="none"
                    />
                  </Box>
                </Collapse>
              )}
            </Box>
          ))}
        </ListEl>

        <ListEl
          title="Describe the problem you want to solve?"
          opacity={!!selectedTools.length || problemInput ? 1 : 0.3}
        >
          <Textarea
            mt={4}
            as={ReactTextareaAutosize}
            minRows={5}
            lineHeight="16px"
            fontSize="13px"
            pt={3.5}
            pb={4}
            minH="auto"
            placeholder="What are you experiencing…"
            resize="none"
            value={problemInput}
            onChange={(e) => setProblemInput(e.target.value)}
          />
        </ListEl>

        <ListEl
          title="How did you hear about Question Base?"
          opacity={
            (!!selectedTools.length && !!problemInput) || referralInput
              ? 1
              : 0.3
          }
        >
          <Textarea
            mt={4}
            as={ReactTextareaAutosize}
            minRows={2}
            lineHeight="16px"
            fontSize="13px"
            pt={3.5}
            pb={4}
            minH="auto"
            placeholder="Let us know where you found us..."
            resize="none"
            value={referralInput}
            onChange={(e) => setReferralInput(e.target.value)}
          />
        </ListEl>
      </OrderedList>
      <Button
        mt={4}
        colorScheme="action"
        ml={4}
        isLoading={submit.isSubmitting}
        onClick={submit.submit}
        isDisabled={!problemInput || !selectedTools.length || !referralInput}
      >
        Submit
      </Button>
    </>
  );
}
