import { IoMdOpen } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IntegrationConnectEndpoint } from '@core/types/types.endpoint.integration';
import {
  NotionDisconnectEndpoint,
  NotionSyncEndpoint,
} from '@core/types/types.endpoint.notion';
import { timeAgo } from '@core/util/util.timeAgo';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryRefresher } from 'src/shared/hooks/shared.hook.useDirectoryRefresher';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsNotion() {
  const { pathname, search, hash } = useLocation();
  const updater = useDirectoryUpdater();
  const team = useDirectoryTeam();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, loading } = useApi<IntegrationConnectEndpoint>(
    '/integration/connect',
    {
      integration: 'notion',
      redirectTo: `${pathname}${search}${hash}`,
      local: window.location.host === 'localhost:3000' ? '1' : undefined,
    },
    isOpen,
  );

  const sync = useSubmitter(async () => {
    const [, res] = await callApi<NotionSyncEndpoint>('/notion/sync', {});

    updater(res);
  });

  const disconnect = useSubmitter(async () => {
    const [, res] = await callApi<NotionDisconnectEndpoint>(
      '/notion/disconnect',
      {},
    );

    updater(res);
  });

  let syncText = `Last synced: Never`;
  if (team.notion_export_last_run) {
    syncText = `Last synced: ${timeAgo(team.notion_export_last_run)}`;
  }
  if (team.notion_export_running) {
    syncText = 'Syncing...';
  }

  if (team.notion_export_error) {
    syncText = `Last synced: ${team.notion_export_error}`;
  }

  const isSyncing = sync.isSubmitting || !!team.notion_export_running;

  useDirectoryRefresher(
    {
      team: true,
    },
    isSyncing,
    5000,
  );

  return (
    <Card variant="outline" shadow="sm" mt={10} maxW="600px">
      <CardBody>
        <Heading size="md">Notion Export (beta)</Heading>
        {!team.notion_export_page_url ? (
          <Popover isOpen={isOpen} onClose={onClose} placement="top">
            <PopoverTrigger>
              <Button
                onClick={onOpen}
                size="sm"
                colorScheme="action"
                isDisabled={isOpen}
                mt={2}
              >
                Connect Notion
              </Button>
            </PopoverTrigger>
            <PopoverContent maxWidth="400px" width="100%">
              <PopoverArrow />
              <PopoverHeader>
                Setup Notion Export
                <PopoverCloseButton />
              </PopoverHeader>
              <PopoverBody p={5}>
                <Text>
                  This will create a private page called &quot;Question
                  Base&quot; in your Notion account. When you are ready to share
                  it, you just move it into your workspace
                </Text>

                <Button
                  as="a"
                  mt={2}
                  href={data?.url}
                  isDisabled={loading}
                  isLoading={loading}
                  colorScheme="action"
                  size="sm"
                >
                  Next
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Stack spacing={4} mt={4} alignItems="flex-start">
            {!!team.notion_export_page_url && (
              <Button
                as="a"
                size="sm"
                variant="link"
                colorScheme="blue"
                cursor="pointer"
                rightIcon={<Icon as={IoMdOpen} />}
                href={team.notion_export_page_url}
                target="_blank"
              >
                Open Notion Document
              </Button>
            )}
            <Text>{syncText}</Text>
            <Stack direction="row" spacing={4}>
              <Button
                onClick={sync.submit}
                isLoading={isSyncing}
                isDisabled={isSyncing}
                size="sm"
                colorScheme="action"
              >
                Sync now
              </Button>
              <Button
                onClick={disconnect.submit}
                size="sm"
                colorScheme="action"
                isLoading={disconnect.isSubmitting}
              >
                Disconnect
              </Button>
            </Stack>
          </Stack>
        )}
        <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
          Export your Question Base to a Notion page
        </Text>
      </CardBody>
    </Card>
  );
}
