import { useEffect, useRef } from 'react';
import { FaHashtag, FaLock, FaSlack } from 'react-icons/fa';

import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { SettingsChannelCountEndpoint } from '@core/types/types.endpoint.settings';

import { SettingsImportModal } from 'src/Settings/components/SettingsModalImport';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryRefresher } from 'src/shared/hooks/shared.hook.useDirectoryRefresher';
import {
  useDirectoryChannels,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';

export function SettingsChannels() {
  const team = useDirectoryTeam();
  const channels = useDirectoryChannels({
    excludeDeleted: true,
    requireQbInChannel: true,
  });

  const needUpdate = !!team.capture_is_running;
  useDirectoryRefresher(
    {
      team: true,
      channels: true,
    },
    needUpdate,
  );

  const { data, refetch } = useApi<SettingsChannelCountEndpoint>(
    '/settings/channelCount',
    {},
  );
  const hasDataRef = useRef(!!data);

  useEffect(() => {
    hasDataRef.current = !!data;
  }, [data]);

  useEffect(() => {
    if (!needUpdate && hasDataRef.current) {
      setTimeout(() => refetch(), 5000);
    }
  }, [refetch, needUpdate]);

  return (
    <Card variant="outline" shadow="sm" maxW="600px" mt={10}>
      <CardBody>
        <Heading size="md">Slack Channels</Heading>
        {channels.map((channel) => {
          const isQueued = team.capture_queue?.find((q) =>
            q.startsWith(channel.id),
          );

          const isRunning = team.capture_queue?.[0]?.startsWith(channel.id);
          let importString = '';
          if (isRunning) {
            importString = 'Importing... — ';
          } else if (isQueued) {
            importString = 'Queued to import — ';
          }

          return (
            <Flex mt={2.5} key={channel.id}>
              <Icon
                mt={0.25}
                as={channel.is_private ? FaLock : FaHashtag}
                mr={2}
              />
              <Box>
                <Text lineHeight={1}>{channel.name}</Text>
                <Text fontSize="sm" color="gray.500" mt={0.5} lineHeight={1.2}>
                  {importString}
                  <Button
                    as="a"
                    target="_blank"
                    variant="link"
                    size="xs"
                    colorScheme="blue"
                    href={`/questions?channel=${channel.id}`}
                  >
                    {data?.[channel.id] ?? 0} questions
                  </Button>
                </Text>
              </Box>
            </Flex>
          );
        })}
        <Flex alignItems="center" gap={2} mt={4}>
          <SettingsImportModal />
          <Button
            leftIcon={<Icon as={FaSlack} />}
            colorScheme="action"
            as="a"
            href="https://www.questionbase.com/docs/invite-to-channels"
            size="sm"
            variant="outline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add to channels
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
}
