import { HiDotsHorizontal } from 'react-icons/hi';

import {
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  RagieConnection,
  RagieDisconnectEndpoint,
} from '@core/types/types.endpoint.ragie';
import { getIntegration } from '@core/util/util.getIntegrations';

import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsConnectionRow({
  connection,
  refetch,
}: {
  connection: RagieConnection;
  refetch: () => Promise<void>;
}) {
  const integration = getIntegration(connection.type);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const disconnect = useSubmitter(async () => {
    await callApi<RagieDisconnectEndpoint>('/ragie/disconnect', {
      connectionId: connection.id,
    });
    await refetch();
  });

  return (
    <Flex
      mt={2}
      display="flex"
      alignItems="center"
      position="relative"
      _hover={{
        '& > button': {
          opacity: 1,
        },
      }}
    >
      <Image src={integration.icon} w={6} h={6} mr={2} />
      {connection.name}

      <Text fontSize="xs" ml={2} color="gray.500">
        ({connection.syncing ? 'Syncing...' : integration.name})
      </Text>

      <Popover
        placement="right"
        closeOnBlur
        closeOnEsc
        onClose={onClose}
        isOpen={isOpen}
      >
        <PopoverTrigger>
          <IconButton
            aria-label="Options"
            icon={<HiDotsHorizontal />}
            variant="ghost"
            size="sm"
            ml={2}
            opacity={0}
            transition="opacity 0.2s"
            onClick={onOpen}
          />
        </PopoverTrigger>
        <PopoverContent width="200px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <VStack align="stretch" spacing={2}>
              <Heading size="sm" fontWeight="medium">
                {integration.name}
              </Heading>
              <Text fontSize="xs">{connection.name}</Text>
              <Text fontSize="xs" color="gray.500">
                Last synced:{' '}
                {connection.lastSyncedAt
                  ? new Date(connection.lastSyncedAt).toLocaleString()
                  : 'Never'}
              </Text>
              <Button
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={() => disconnect.submit()}
                isLoading={disconnect.isSubmitting}
              >
                Disconnect
              </Button>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
