import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function AppSuccessModal() {
  const [success, setSuccess] = useSingleSearchParam('success');

  let channels = useDirectoryChannels({
    requireQbInChannel: true,
    excludeDeleted: true,
  });
  channels = [...channels, ...channels, ...channels, ...channels];

  const renderedChannel = channels.map((c) => (
    <Link
      display="inline-block"
      key={c.id}
      href={`https://slack.com/app_redirect?channel=${c.id}`}
      target="_blank"
      rel="noopener noreferrer"
      color="blue"
      fontWeight="bold"
    >
      #{c.name}
    </Link>
  ))[0];

  return (
    <Modal isOpen={!!success} onClose={() => setSuccess(undefined)} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {success === 'onboarding' && (
          <>
            <ModalHeader>👋 Voila!</ModalHeader>
            <ModalBody pb={6}>
              <Text>
                Your Question Base is ready.
                <br />
                Head out to {renderedChannel} to experience the bot in action!
              </Text>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  overflow: 'scroll',
                }}
                id="my-cal-inline"
              ></div>
            </ModalBody>
          </>
        )}
        {success === 'integration' && (
          <>
            <ModalHeader>🎉 Integration connected</ModalHeader>
            <ModalBody pb={6}>
              <Text>
                Your data is syncing.
                <br />
                Once done, you can ask questions in Slack
              </Text>
              <Text mt={2}>{renderedChannel}</Text>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
