import { useState } from 'react';
import { LuExternalLink } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Icon,
  Text,
} from '@chakra-ui/react';
import { BillingLinkEndpoint } from '@core/types/types.endpoint.billing';
import { RagiePartition } from '@core/types/types.endpoint.ragie';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useIsPro } from 'src/shared/hooks/shared.hook.useIsPro';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function SettingsAccountType({
  partition,
}: {
  partition?: RagiePartition;
}) {
  const { pathname } = useLocation();
  // Determine if we are running locally
  const team = useDirectoryTeam();
  const [loadBilling, setLoadBilling] = useState(false);

  const { data, loading } = useApi<BillingLinkEndpoint>(
    '/billing/link',
    {},
    loadBilling,
  );

  const isPro = useIsPro();

  const local = window.location.hostname.startsWith('localhost');

  const currentPages = partition?.stats.pagesHostedTotal || 0;
  const maxPages = partition?.limits.pagesHostedLimitMax || 0;

  return (
    <Card variant="outline" shadow="sm" maxW="600px">
      <CardBody>
        <Heading size="md">Account status</Heading>
        <Text mt={2} mb={2}>
          Your team is on the <b>{isPro ? 'Pro' : 'Free'} plan</b>
        </Text>
        <Text fontSize="sm" fontWeight="medium" color="gray.600" mr={2}>
          Integration Usage:
        </Text>
        <Text
          fontSize="sm"
          fontWeight="bold"
          color={currentPages > maxPages * 0.8 ? 'orange.500' : 'green.500'}
          mb={3}
        >
          {currentPages.toFixed(1)} / {maxPages} pages
        </Text>

        {(!team.stripe_customer_id || !isPro) && (
          <Flex>
            <Button
              mr={5}
              as="a"
              href={`/v1/billing/pay?teamId=${team.id}&redirectUri=${pathname}${local ? '&local=1' : ''}`}
              size="sm"
              colorScheme="action"
            >
              Upgrade to Pro
            </Button>
            <Button
              colorScheme="action"
              as="a"
              target="_blank"
              size="sm"
              variant="outline"
              href="mailto:support@questionbase.com"
            >
              Request custom price
            </Button>
          </Flex>
        )}
        {!!team.stripe_customer_id && !data && (
          <Button
            variant="link"
            colorScheme="action"
            onClick={() => {
              setLoadBilling(true);
            }}
            isDisabled={loading}
          >
            {loading ? 'Generating link...' : 'Open billing'}
          </Button>
        )}

        {data && (
          <Button
            as="a"
            target="_blank"
            href={data.url}
            variant="link"
            colorScheme="action"
            rightIcon={<Icon w={5} h={5} as={LuExternalLink} />}
          >
            Stripe Billing Portal
          </Button>
        )}
      </CardBody>
    </Card>
  );
}
