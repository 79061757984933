import { QuestionSearchItem } from '@core/types/types.common';
import { DBQuestion } from '@core/types/types.db';
import { QuestionVerifyEndpoint } from '@core/types/types.endpoint.question';
import { Editor } from '@tiptap/react';

import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function useSaver(
  question: Pick<
    DBQuestion | QuestionSearchItem,
    'title' | 'id' | 'answer_json' | 'verified_by'
  >,
  title: string,
  editor: Editor | null,
  onCompleted?: () => void,
) {
  const broadcast = useBroadcaster();

  return useSubmitter(async () => {
    const trimmed = title.trim();
    const newAnswerJson = JSON.stringify(editor?.getJSON());

    if (!editor || !trimmed) return;
    if (
      trimmed !== question.title ||
      newAnswerJson !== question.answer_json ||
      question.verified_by === 'UNVERIFIED'
    ) {
      const [, res] = await callApi<QuestionVerifyEndpoint>(
        '/question/verify',
        {
          title: trimmed,
          id: question.id,
          answerBase64: btoa(encodeURIComponent(newAnswerJson)),
        },
      );

      if (res) {
        broadcast({
          event: 'question:save',
          question: res,
        });
        onCompleted?.();
      }
    }
  });
}
