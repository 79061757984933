import { useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  OrderedList,
  Select,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { DirectoryChannelsEndpoint } from '@core/types/types.endpoint.directory';
import { OnboardingSetupEndpoint } from '@core/types/types.endpoint.onboarding';

import { AddBot } from 'src/App/App.AddBot';
import { ListEl } from 'src/App/App.ListEl';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryRefresher } from 'src/shared/hooks/shared.hook.useDirectoryRefresher';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppOnboardingInstall() {
  const refresh = useDirectoryRefresher(
    {
      team: true,
      channels: true,
      users: true,
      me: true,
    },
    false,
    0,
  );
  const team = useDirectoryTeam();
  const isInstalled = !!team.slack_bot_user_id;

  const [importDays, setImportDays] = useState('30');
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

  const { data: channels, loading } = useApi<DirectoryChannelsEndpoint>(
    '/directory/channels',
    {},
    isInstalled,
  );

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<OnboardingSetupEndpoint>(
      '/onboarding/setup',
      {
        channelIds: selectedChannels,
        days: Number(importDays),
      },
    );

    if (res) {
      await refresh.submit();
    }
  });

  return (
    <>
      <OrderedList>
        <ListEl
          title="Add the bot to your Slack Workspace"
          subtitle="This will allow the bot to join."
        >
          <Spacer height={6} />
          {isInstalled ? (
            <Flex alignItems="center" pb={10}>
              <Icon
                as={IoIosCheckmark}
                h={6}
                background="rgba(176, 248, 120, 1)"
                borderRadius={12}
                w={6}
                color="rgba(27, 27, 27, 1)"
              />
              <Text ml={3} color="rgba(27, 27, 27, 1)">
                Bot successfully installed!
              </Text>
            </Flex>
          ) : (
            <AddBot />
          )}
        </ListEl>
        <ListEl
          opacity={isInstalled ? 1 : 0.3}
          title="Select channels for the bot to join"
          subtitle="Capture knowledge and automatically respond to
      questions in these channels:"
        >
          {loading && <Spinner />}
          {channels?.map((c, i) => (
            <Box key={c.id} mt={i === 0 ? 6 : 3}>
              <Checkbox
                isChecked={selectedChannels.includes(c.id)}
                onChange={(e) => {
                  // If the option is already selected, remove it from the array
                  // Otherwise, add it to the array
                  setSelectedChannels(
                    e.target.checked
                      ? [...selectedChannels, c.id]
                      : selectedChannels.filter((id) => id !== c.id),
                  );
                }}
              >
                #{c.name}
              </Checkbox>
            </Box>
          ))}
        </ListEl>
        <ListEl
          title="Convert channel history to FAQs"
          subtitle="Capture previously asked questions from your channels"
          opacity={isInstalled && selectedChannels.length > 0 ? 1 : 0.3}
          mt={6}
        >
          <Select
            mt={3}
            value={importDays}
            onChange={(e) => setImportDays(e.target.value)}
            maxW="240px"
          >
            <option value="0">No, thanks</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 90 days</option>
          </Select>
        </ListEl>
      </OrderedList>
      <Button
        mt={4}
        colorScheme="action"
        ml={4}
        onClick={submit.submit}
        isLoading={submit.isSubmitting}
        isDisabled={!isInstalled || !selectedChannels.length}
      >
        Next
      </Button>
    </>
  );
}
