import { useEffect } from 'react';

import { Card, CardBody, Heading, Text } from '@chakra-ui/react';
import { RagieListEndpoint } from '@core/types/types.endpoint.ragie';

import { SettingsAddIntegrationButton } from 'src/Settings/components/SettingsAddIntegrationButton';
import { SettingsConnectionRow } from 'src/Settings/components/SettingsConnectionRow';
import { SettingsAccountType } from 'src/Settings/SettingsAccountType';
import { SettingsChannels } from 'src/Settings/SettingsChannels';
import { SettingsExperts } from 'src/Settings/SettingsExperts';
import { SettingsFeatures } from 'src/Settings/SettingsFeatures';
import { SettingsNotion } from 'src/Settings/SettingsNotion';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';

export function SettingsPage() {
  const { data, refetch } = useApi<RagieListEndpoint>('/ragie/list', {});

  useEffect(() => {
    const tId = setTimeout(() => refetch(), 5000);
    return () => clearTimeout(tId);
  }, []);

  return (
    <>
      <SettingsAccountType partition={data?.partition} />
      <Card variant="outline" shadow="sm" maxW="600px" mt={12}>
        <CardBody>
          <Heading size="md">Integrations</Heading>
          <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
            Answer questions in Slack with data from:
          </Text>
          {data?.connections.map((c) => (
            <SettingsConnectionRow
              key={c.id}
              connection={c}
              refetch={refetch}
            />
          ))}
          <SettingsAddIntegrationButton
            connectionCount={data?.connections.length ?? 0}
          />
        </CardBody>
      </Card>

      <SettingsExperts />
      <SettingsChannels />
      <SettingsFeatures />
      <SettingsNotion />
    </>
  );
}
